<template>
  <div class="min-h-screen bg-white flex">
    <div class="flex flex-1 pb-16 sm:pt-8 sm:pb-24 flex-col lg:flex-none ">
      <div class="px-4 sm:px-12 h-16 flex justify-between items-center ">
        <Button type="text" @click="goToRegister" color="black">
          <template #icon-before>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </template>
          Retour
        </Button>
        <div class="text-sm">
          <div class="text-gray-400 uppercase ">Etape 02/03</div>
          <div class="text-gray-900 font-semibold">Vérification du compte</div>
        </div>
      </div>
      <div class="flex-1 flex flex-col justify-center px-4 py-8 sm:px-6  lg:px-20 xl:px-24">
        <div class="mx-auto w-full max-w-sm lg:w-96">
          <div v-if="emailConfirmed">
            <div>
              <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
                Votre mail est <span class="text-primary-500">vérifié</span>
                <Emoji class="pl-2" data="👌"></Emoji>
              </h2>
              <p class="pt-4 text-gray-400 tracking-wide font-light">
                Maintenant que nous avons pu vérifier que vous étiez le détenteur de l'adresse email
                communiquée, nous pouvons continuer vers la derniére étape.
              </p>
            </div>

            <div class="mt-8">
              <div class="mt-6">
                <p class="text-gray-900">
                  Vous allez être redirigé dans {{ countdownBeforeRedirection }}
                  {{ countdownBeforeRedirection > 1 ? 'secondes' : 'seconde' }} ...
                </p>
                <Button class="mt-8" color="primary" @click="goNext">Continuer</Button>
              </div>
            </div>
          </div>
          <div v-else>
            <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
              <span class="text-primary-500">Vérification</span> de votre adresse mail en cours ...
            </h2>
            <div v-if="showErrorMessage" class="pt-8">
              <div class="rounded-md bg-red-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <!-- Heroicon name: x-circle -->
                    <svg
                      class="h-5 w-5 text-red-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="ml-3">
                    <h3 class="text-sm font-medium text-red-800">
                      Une erreur est survenue.
                      <a
                        href="mailto:hello@bridge.immo"
                        class="font-medium underline text-red-700 hover:text-red-600"
                      >
                        Contactez-nous
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div class="pt-4 flex" v-else>
              <svg
                class="animate-spin mr-2 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p class=" text-gray-400 tracking-wide font-light">
                Veuillez patienter svp <Emoji data="🙏"></Emoji>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:flex relative w-0 flex-1 bg-primary-500 justify-center items-center">
      <div class="text-white max-w-md p-16 xl:max-w-xl">
        <h3 class=" text-4xl xl:text-5xl font-semibold">
          Nous vous souhaitons un bon retour sur Bridge <Emoji data="👋"></Emoji>
        </h3>
        <p class="text-xl xl:text-2xl mt-10 font-light ">
          Après votre connexion, vous pourrez de nouveau gérer vos dossiers de locataire, planifier
          vos visites, télécharger votre bail, accéder à des conseils d’experts et bien d’autres
          fonctionnalités encore !
        </p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useConfirmEmailMutation } from '@/generated/graphql';
import { Button, Emoji } from '@bridge-immo/ui';

import { useRoute } from 'vue-router';
import { onLogin } from '@bridge-immo/common';
// import { redirectAfterLogin } from '@bridge-immo/common/dist/src';

export default defineComponent({
  components: {
    Button,
    Emoji
  },
  setup() {
    const route = useRoute();
    const countdownBeforeRedirection = ref(15);
    const emailConfirmed = ref(false);
    const showErrorMessage = ref(false);

    const { mutate: confirm, onDone, onError } = useConfirmEmailMutation({});

    onMounted(() => {
      if (route.params.token) {
        setTimeout(() => {
          confirm({ token: route.params.token.toString() });
        }, 5000);
      } else {
        showErrorMessage.value = true;
      }
    });

    onDone(async result => {
      if (result?.data?.confirmEmail.user.role) {
        emailConfirmed.value = true;
        // user = result?.data?.confirmEmail.user;
        await onLogin(result?.data?.confirmEmail.accessToken);
      } else {
        showErrorMessage.value = true;
      }
    });
    onError(error => {
      console.log(error);
      showErrorMessage.value = true;
    });

    const decrementCountDown = () => {
      if (countdownBeforeRedirection.value > 0) {
        setTimeout(() => {
          countdownBeforeRedirection.value--;
        }, 1000);
      } else {
        // redirectAfterLogin(user, route);
      }
    };
    watch(countdownBeforeRedirection, decrementCountDown, { immediate: true });

    const goToRegister = () => {
      window.location.assign(`${process.env.VUE_APP_ROOT_URL}/landlord/register`);
    };

    const goNext = () => {
      // redirectAfterLogin(user, route);
    };

    return { goToRegister, goNext, countdownBeforeRedirection, emailConfirmed, showErrorMessage };
  }
});
</script>

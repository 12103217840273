
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useConfirmEmailMutation } from '@/generated/graphql';
import { Button, Emoji } from '@bridge-immo/ui';

import { useRoute } from 'vue-router';
import { onLogin } from '@bridge-immo/common';
// import { redirectAfterLogin } from '@bridge-immo/common/dist/src';

export default defineComponent({
  components: {
    Button,
    Emoji
  },
  setup() {
    const route = useRoute();
    const countdownBeforeRedirection = ref(15);
    const emailConfirmed = ref(false);
    const showErrorMessage = ref(false);

    const { mutate: confirm, onDone, onError } = useConfirmEmailMutation({});

    onMounted(() => {
      if (route.params.token) {
        setTimeout(() => {
          confirm({ token: route.params.token.toString() });
        }, 5000);
      } else {
        showErrorMessage.value = true;
      }
    });

    onDone(async result => {
      if (result?.data?.confirmEmail.user.role) {
        emailConfirmed.value = true;
        // user = result?.data?.confirmEmail.user;
        await onLogin(result?.data?.confirmEmail.accessToken);
      } else {
        showErrorMessage.value = true;
      }
    });
    onError(error => {
      console.log(error);
      showErrorMessage.value = true;
    });

    const decrementCountDown = () => {
      if (countdownBeforeRedirection.value > 0) {
        setTimeout(() => {
          countdownBeforeRedirection.value--;
        }, 1000);
      } else {
        // redirectAfterLogin(user, route);
      }
    };
    watch(countdownBeforeRedirection, decrementCountDown, { immediate: true });

    const goToRegister = () => {
      window.location.assign(`${process.env.VUE_APP_ROOT_URL}/landlord/register`);
    };

    const goNext = () => {
      // redirectAfterLogin(user, route);
    };

    return { goToRegister, goNext, countdownBeforeRedirection, emailConfirmed, showErrorMessage };
  }
});
